@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Avenir';
  src: url('/public/fonts/Avenirnext.otf') format('otf');
}

.without-ring {
  @apply focus:ring-0 focus:ring-offset-0;
}

select:invalid {
  color: gray;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@layer utilities {
  .carsAndStars {
    background-image: url('/src/components/carsAndStars.svg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .trucksAndStars {
    background-image: url('/src/components/trucksAndStars.svg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .moneybagAndStars {
    background-image: url('/src/components/moneybagAndStars.svg');
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
